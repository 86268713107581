/**
 * Analytics Page
 * This page is intended to empower the customer to uncover
 * insights and trends in their data, over longer periods of time.
 */
import { useState, useEffect } from 'react';
import { useData } from '../../hooks/useData';
import type { DayPartData } from '../../types/DayPartData';
import type { RegionsStats } from '../../types/RegionsStats';
import type { Average } from '../../types/Average';
import { getDayPartsData, getRegionsStats, getAverages } from '../../utils/api';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationMultiple from '../../components/dropdowns/DropdownLocationMultiple';
import Datepicker from '../../components/time/Datepicker';
import DriveThruLobbyToggle from '../../components/buttons/DriveThruLobbyToggle';
import HelpButton from '../../components/buttons/HelpButton';
import HelpModal from '../../components/modals/ModalHelpAnalytics';
import StationsGraph from '../../components/graphs/analytics/StationsGraph';
import TrendsGraph from '../../components/graphs/analytics/TrendsGraph';
import DayPartsGraph from '../../components/graphs/analytics/DayPartsGraph';
import PageLayout from '../../components/ui/PageLayout';

export default function AnalyticsContainer(): JSX.Element {
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 7);

  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [dayPartsData, setDayPartsData] = useState<DayPartData[]>([]);
  const [regionsData, setRegionsData] = useState<RegionsStats[]>([]);
  const [trendsData, setTrendsData] = useState<Average[]>([]);
  const {
    selectedFranchise,
    selectedLocations,
    selectedDates,
    setSelectedDates,
    toggled,
  } = useData();

  // Set user locations on load.
  useEffect(() => {
    setSelectedDates([oneWeekAgo, today]);
  }, []);

  // Get day parts data when toggled or date or location or franchise changes.
  useEffect(() => {
    async function getData(): Promise<void> {
      if (selectedFranchise === null || selectedLocations.length === 0) {
        return;
      }

      // Get day parts data.
      getDayPartsData(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru'
      )
        .then((data) => {
          setDayPartsData(data);
        })
        .catch((error) => {
          console.error(error);
        });

      // Get regions data.
      getRegionsStats(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru'
      )
        .then((data) => {
          setRegionsData(data);
        })
        .catch((error) => {
          console.error(error);
        });

      // Get trends data.
      getAverages(
        selectedFranchise.id,
        selectedDates[0],
        selectedDates[1],
        selectedLocations.map((location) => location.id),
        toggled === 'drive-thru'
      )
        .then((data) => {
          setTrendsData(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getData().catch((error) => {
      console.error(error);
    });
  }, [toggled, selectedDates, selectedLocations, selectedFranchise]);

  // Handle date change
  function handleDateChange(dates: Date[]): void {
    setSelectedDates(dates);
  }

  return (
    <PageLayout>
      <div data-cy="analytics">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              Analytics ✨
            </h1>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {/* Selectors */}
          <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
            <DropdownFranchise />
            <DropdownLocationMultiple />
            <Datepicker
              initDates={selectedDates}
              handleDateChange={handleDateChange}
            />
            <DriveThruLobbyToggle />
            <HelpButton
              onClick={() => {
                setShowHelpModal(true);
              }}
            />
          </div>
          {/* Graphs */}
          <TrendsGraph trendsData={trendsData} />
          <StationsGraph regionsData={regionsData} />
          <DayPartsGraph dayPartsData={dayPartsData} />
        </div>
        {/* Help modal */}
        <HelpModal isOpen={showHelpModal} setIsOpen={setShowHelpModal} />
      </div>
    </PageLayout>
  );
}
