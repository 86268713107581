/**
 * Video Player for displaying live and historical video streams
 * via the custom Hellometer video streaming solution.
 */
import { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import type Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import { useCamera } from '../../hooks/useCamera';
import {
  getHistoricalManifestUrl,
  getLiveManifestUrl,
  getStreamTimes,
} from '../../utils/video';
import type { Location } from '../../types/Location';
import VideoPlayer from './VideoPlayer';

export default function VideoPlayerCustom({
  location,
  cameraId,
}: {
  location: Location;
  cameraId: string;
}): JSX.Element {
  const [player, setPlayer] = useState<Player | null>(null);
  const [manifestUrl, setManifestUrl] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isLive, playbackSpeed, videoDateTime, isPlaying, setIsPlaying } =
    useCamera();

  // On load, initialize videojs
  useEffect(() => {
    if (videoRef.current === null) {
      return;
    }
    const playerObject = videojs(videoRef.current, {
      autoplay: true,
      muted: true,
      playsinline: true,
      controls: true,
      preload: 'auto',
      userActions: {
        click: false,
      },
      html5: {
        vhs: {
          overrideNative: true,
        },
      },
    });
    setPlayer(playerObject);

    // Add listeners.
    playerObject.on('error', () => {
      playerObject.load();
      playerObject.play()?.catch((error) => {
        console.error(error);
      });
    });

    playerObject.on('loadedmetadata', () => {
      const w = wrapperRef.current?.clientWidth;
      playerObject.width(w);
    });

    // Cleanup.
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [videoRef.current]);

  // When the manifest URL changes, update the player source.
  useEffect(() => {
    if (player === null || manifestUrl === null) {
      return;
    }
    player.src({
      src: manifestUrl,
      type: 'application/x-mpegURL',
    });
    setIsPlaying(true);
  }, [manifestUrl, player]);

  // Go to live or historical video.
  useEffect(() => {
    if (isLive) {
      getLiveManifestUrl(location.id, cameraId)
        .then((manifestUrl: string | null) => {
          setManifestUrl(manifestUrl);
        })
        .catch((error) => {
          console.error('Error getting live video', error);
        });
    } else {
      const { startTime, endTime } = getStreamTimes(
        videoDateTime,
        location.timezone
      );
      getHistoricalManifestUrl(location.id, cameraId, startTime, endTime)
        .then((manifestUrl: string | null) => {
          setManifestUrl(manifestUrl);
        })
        .catch((error) => {
          console.error('Error getting historical video', error);
        });
    }
  }, [isLive, videoDateTime]);

  // Change video speed.
  useEffect(() => {
    if (player === null) {
      return;
    }
    try {
      player.playbackRate(playbackSpeed);
      player.defaultPlaybackRate(playbackSpeed);
    } catch (err) {
      console.log(err);
    }
  }, [playbackSpeed]);

  // Play or pause the video based on the isPlaying prop.
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (!isPlaying) {
      player.pause();
    } else {
      player.play()?.catch((err) => {
        console.log(err);
      });
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isLive) {
      return;
    }
    // Refresh every 60 seconds.
    const interval = setInterval(() => {
      player?.src({
        src: manifestUrl,
        type: 'application/x-mpegURL',
      });

      console.log('Refreshing video.');
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <VideoPlayer
      location={location}
      cameraId={cameraId}
      videoRef={videoRef}
      wrapperRef={wrapperRef}
    />
  );
}
