/**
 * Presentational component for a video player.
 */
import { useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IconButton } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import download from 'downloadjs';
import { isMobile } from '../../utils/screen';
import type { Location } from '../../types/Location';


export default function VideoPlayer({
  location,
  cameraId,
  videoRef,
  wrapperRef,
}: {
  location: Location;
  cameraId: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  wrapperRef: React.RefObject<HTMLDivElement>;
}): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Handle taking a snapshot of the video.
  function snapshot(): void {
    if (videoRef.current === null || canvasRef.current === null) {
      return;
    }

    const context = canvasRef.current.getContext('2d');
    if (context === null) {
      return;
    }

    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    context.drawImage(
      videoRef.current,
      0,
      0,
      videoRef.current.videoWidth,
      videoRef.current.videoHeight
    );
    canvasRef.current.toBlob((blob: Blob | null) => {
      if (blob === null) {
        return;
      }
      download(blob, 'snapshot.jpg', 'img/jpeg');
    });
  }

  return (
    <div className="bg-white rounded-md">
      <div className="flow-root">
        <h3 className="m-3 float-left">
          Camera: {cameraId}
          {cameraId === "MONITOR" && location && (
            <span> - {location.displayName}: L{location.id}</span>
          )}
        </h3>
        <div className={`float-right pr-3 pt-3 ${isMobile() ? 'hidden' : ''}`}>
          <IconButton onClick={snapshot} size="small">
            <CameraAlt />
          </IconButton>
        </div>
      </div>
      <div
        data-vjs-player
        ref={wrapperRef}
        className="overflow-hidden rounded-b-lg"
      >
        <TransformWrapper>
          <TransformComponent>
            <video
              controls
              playsInline
              autoPlay
              ref={videoRef}
              className="video-js"
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
      <canvas
        id="canvas"
        style={{ overflow: 'auto', display: 'none' }}
        ref={canvasRef}
      ></canvas>
    </div>
  );
}
