/**
 * Admin Settings Page
 * This page is intended for customers to manage their Admin settings.
 */
import PageLayout from '../../../../components/ui/PageLayout';
import SettingsSidebar from '../../../../components/settings/SettingsSidebar';
import { useData } from '../../../../hooks/useData';
import { useEffect, useState } from 'react';
import {
  deleteUserProfile,
  getFranchiseUsers,
  postUserProfile,
  updateFranchiseConfiguration,
  updateUserProfile,
} from '../../../../utils/api';
import type { FranchiseUser } from '../../../../types/FranchiseUser';
import UserItem from '../../../../components/settings/admin/UserItem';
import NewUserItem from '../../../../components/settings/admin/NewUserItem';
import { useLoad } from '../../../../hooks/useLoad';
import { useToast } from '../../../../hooks/useToast';
import FranchiseConfiguration from './franchise/FranchiseConfiguration';

function Admin(): JSX.Element {
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const { 
    selectedFranchise, 
    franchiseUsers, 
    setFranchiseUsers, 
    franchiseLocations, 
    setFranchiseLocations,
    franchiseConfiguration,
    setFranchiseConfiguration
  } = useData();
  const { setIsLoading } = useLoad();
  const { setToast } = useToast();

  useEffect(() => {
    setIsLoading(true);
    getFranchiseUsers(selectedFranchise?.id ?? '')
      .then((data) => {
        setFranchiseUsers(data.userData);
        setFranchiseLocations(data.franchiseLocations);
        setFranchiseConfiguration(data.franchiseConfiguration!);
      })
      .catch((error) => {
        console.error(
          `Error fetching users for franchise ${selectedFranchise?.id}:`,
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedFranchise]);

  const newUser = (newUser: FranchiseUser): void => {
    setIsLoading(true);
    postUserProfile(newUser)
      .then((value) => {
        if (value) {
          getFranchiseUsers(selectedFranchise?.id ?? '')
            .then((data) => {
              setFranchiseUsers(data.userData);
              setFranchiseLocations(data.franchiseLocations);
              setFranchiseConfiguration(data.franchiseConfiguration!);
            })
            .catch((error) => {
              console.error(
                `Error fetching users for franchise ${selectedFranchise?.id}:`,
                error
              );
            });
        }
      })
      .catch((error) => {
        console.error(
          `Error adding user for franchise ${selectedFranchise?.id}:`,
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
        setToast({
          show: true,
          message: 'New user added',
          type: 'success',
        });
      });
  };

  const editUser = (updatedUser: FranchiseUser): void => {
    setIsLoading(true);
    updateUserProfile(updatedUser)
      .then((value) => {
        if (value) {
          getFranchiseUsers(selectedFranchise?.id ?? '')
            .then((data) => {
              setFranchiseUsers(data.userData);
              setFranchiseLocations(data.franchiseLocations);
              setFranchiseConfiguration(data.franchiseConfiguration!);
            })
            .catch((error) => {
              console.error(
                `Error fetching users for franchise ${selectedFranchise?.id}:`,
                error
              );
            });
        }
      })
      .catch((error) => {
        console.error(
          `Error updating user for franchise ${selectedFranchise?.id}:`,
          error
        );
      })
      .finally(() => {
        setIsLoading(false);
        setToast({
          show: true,
          message: 'User modified',
          type: 'success',
        });
      });
  };

  const deleteUser = (user: FranchiseUser): void => {
    setIsLoading(true);
    // Check if user being deleted belongs to mailing list
    let editingFranchiseConfiguration = franchiseConfiguration;
    let newMailingList;
    if (franchiseConfiguration!.emailConfiguration.mailingList.includes(user.email)) {
        // Remove the email if it already exists
        newMailingList = franchiseConfiguration!.emailConfiguration.mailingList.filter(email => email !== user.email);
        editingFranchiseConfiguration!.emailConfiguration.mailingList = newMailingList;
    }
    
    updateFranchiseConfiguration(selectedFranchise!.id, editingFranchiseConfiguration!)
      .then(() => {
        deleteUserProfile(user)
          .then((value) => {
            if (value) {
              getFranchiseUsers(selectedFranchise?.id ?? '')
                .then((data) => {
                  setFranchiseUsers(data.userData);
                  setFranchiseLocations(data.franchiseLocations);
                  setFranchiseConfiguration(data.franchiseConfiguration!);
                })
                .catch((error) => {
                  console.error(
                    `Error fetching users for franchise ${selectedFranchise?.id}:`,
                    error
                  );
                });
            }
          })
          .catch((error) => {
            console.error(
              `Error deleting user for franchise ${selectedFranchise?.id}:`,
              error
            );
          })
          .finally(() => {
            setIsLoading(false);
            setToast({
              show: true,
              message: 'User deleted',
              type: 'success',
            });
          });
      });
  };

  return (
    <PageLayout>
      <>
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
            Admin Settings ✨
          </h1>
        </div>

        {/* Content */}
        <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div className="flex flex-col md:flex-row md:-mr-px">
            <SettingsSidebar />
            <div className="grow">
              <div className="p-6 space-y-6">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
                  {selectedFranchise?.displayName} administration
                </h2>
                <div className="flex flex-wrap w-full justify-between gap-4">
                  <h2 className="my-auto text-l text-slate-800 dark:text-slate-100 font-bold">
                    User Management
                  </h2>
                  <button
                    type="button"
                    className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
                    onClick={() => {
                      setShowNewUserModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-3 h-3 text-white me-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Add new user
                  </button>
                </div>
                <div className="flex flex-col w-full">
                  {franchiseUsers.map((user) => (
                    <UserItem
                      key={user.userId}
                      currentUser={user}
                      franchiseLocations={franchiseLocations}
                      onSave={editUser}
                      onDelete={deleteUser}
                    />
                  ))}
                </div>
                <div hidden={true}>
                  <NewUserItem
                    franchiseLocations={franchiseLocations}
                    franchiseId={selectedFranchise?.id ?? ''}
                    isOpen={showNewUserModal}
                    setIsOpen={setShowNewUserModal}
                    onSave={newUser}
                  />
                </div>
                {/* <FranchiseConfigurationSection /> */}
                <div className="justify-between gap-4">
                  <h2 className="my-auto text-l text-slate-800 dark:text-slate-100 font-bold">
                    Franchise Configuration Management
                  </h2>
                </div>
                <div className="flex flex-col w-full">
                  <FranchiseConfiguration/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </PageLayout>
  );
}

export default Admin;
