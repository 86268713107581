/**
 * Custom React hook for handling profile and location data at the component level.
 */

import { createContext, useContext, useState } from 'react';
import type { UserProfile } from '../types/UserProfile';
import type { Franchise } from '../types/Franchise';
import type { Location } from '../types/Location';
import { FranchiseUser } from '../types/FranchiseUser';
import { FranchiseLocation } from '../types/FranchiseLocation';
import { FranchiseConfiguration } from '../types/FranchiseConfiguration';

// Interface for the data context.
interface DataContext {
  profile: UserProfile | null;
  handleProfileChange: (profile: UserProfile) => void;
  userFranchises: Franchise[];
  setUserFranchises: (franchises: Franchise[]) => void;
  franchiseUsers: FranchiseUser[],
  setFranchiseUsers: (franchiseUsers: FranchiseUser[]) => void,
  franchiseLocations: FranchiseLocation[],
  setFranchiseLocations: (franchiseLocations: FranchiseLocation[]) => void,
  franchiseConfiguration: FranchiseConfiguration | null,
  setFranchiseConfiguration: (franchiseConfiguration: FranchiseConfiguration) => void,
  userLocations: Location[];
  setUserLocations: (locations: Location[]) => void;
  selectedFranchise: Franchise | null;
  handleFranchiseChange: (id: string) => void;
  displayedLocations: Location[];
  setDisplayedLocations: (locations: Location[]) => void;
  selectedLocation: Location | null;
  setSelectedLocation: (location: Location) => void;
  selectedLocations: Location[];
  handleSelectedLocationsChange: (id: string) => void;
  selectedDates: Date[];
  setSelectedDates: (dates: Date[]) => void;
  toggled: 'lobby' | 'drive-thru';
  handleToggle: (toggled: 'lobby' | 'drive-thru') => void;
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Dummy context
const dummyContext: DataContext = {
  profile: null,
  handleProfileChange: (profile: UserProfile) => {},
  userFranchises: [],
  setUserFranchises: (franchises: Franchise[]) => {},
  franchiseUsers: [],
  setFranchiseUsers: (franchiseUsers: FranchiseUser[]) => {},
  franchiseLocations: [],
  setFranchiseLocations: (franchiseLocations: FranchiseLocation[]) => {},
  franchiseConfiguration: null,
  setFranchiseConfiguration: (franchiseConfiguration: FranchiseConfiguration) => {},
  userLocations: [],
  setUserLocations: (locations: Location[]) => {},
  selectedFranchise: null,
  handleFranchiseChange: (id: string) => {},
  displayedLocations: [],
  setDisplayedLocations: (locations: Location[]) => {},
  selectedLocation: null,
  setSelectedLocation: (location: Location) => {},
  selectedLocations: [],
  handleSelectedLocationsChange: (id: string) => {},
  selectedDates: [new Date(), new Date()],
  setSelectedDates: (dates: Date[]) => {},
  toggled: 'drive-thru',
  handleToggle: (toggled: 'lobby' | 'drive-thru') => {},
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => {},
};

// Create the data context.
const dataContext = createContext(dummyContext);

// Create the data provider.
export const ProvideData: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const data = useProvideData();
  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
};

// Create the useData hook.
export function useData(): DataContext {
  return useContext(dataContext);
}

// Create the useProvideData hook.
const useProvideData = (): DataContext => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [userFranchises, setUserFranchises] = useState<Franchise[]>([]);
  const [franchiseUsers, setFranchiseUsers] = useState<FranchiseUser[]>([]);
  const [franchiseLocations, setFranchiseLocations] = useState<
    FranchiseLocation[]
  >([]);
  const [franchiseConfiguration, setFranchiseConfiguration] = useState<
  FranchiseConfiguration | null
>(null);
  const [userLocations, setUserLocations] = useState<Location[]>([]);
  const [selectedFranchise, setSelectedFranchise] = useState<Franchise | null>(
    null
  );
  const [displayedLocations, setDisplayedLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const initToggle =
    (localStorage.getItem('toggled') as 'lobby' | 'drive-thru') ?? 'drive-thru';
  const [toggled, setToggled] = useState<'lobby' | 'drive-thru'>(initToggle);

  // Handle setting the profile.
  function handleProfileChange(profile: UserProfile): void {
    setProfile(profile);
    setUserLocations(profile.locations);
    const franchises = profile.franchises;
    setUserFranchises(franchises);
    if (franchises.length === 0) {
      return;
    }
    // Set the selected franchise to whatever's cached, or the first one.
    const cachedFranchiseId = localStorage.getItem('selected-franchise-id');
    let franchise = franchises[0];
    if (cachedFranchiseId !== null) {
      const cachedFranchise = franchises.find(
        (franchise) => franchise.id === cachedFranchiseId
      );
      if (cachedFranchise !== undefined) {
        franchise = cachedFranchise;
      }
    }
    setSelectedFranchise(franchise);
    setDisplayedLocations(
      profile.locations.filter(
        (location) => location.franchiseId === franchise.id
      )
    );
    const foundLocation = profile.locations.find(
      (location) => location.franchiseId === franchise.id
    );
    setSelectedLocation(foundLocation ?? null);
    // Set the selected locations to the franchise's locations if they're cached, else all of them.
    const cachedLocationIds = localStorage
      .getItem(`franchise-${franchise.id}-selected-location-ids`)
      ?.split(',');
    if (cachedLocationIds !== undefined) {
      setSelectedLocations(
        profile.locations.filter((location) =>
          cachedLocationIds.includes(location.id)
        )
      );
      return;
    }
    setSelectedLocations(
      profile.locations.filter(
        (location) => location.franchiseId === franchise.id
      )
    );
  }

  // Handle toggle change.
  function handleToggle(toggled: 'lobby' | 'drive-thru'): void {
    setToggled(toggled);
    // Cache the toggled state.
    localStorage.setItem('toggled', toggled);
  }

  // Handle changing a franchise.
  function handleFranchiseChange(id: string): void {
    const franchise = userFranchises.find((franchise) => franchise.id === id);
    if (franchise === undefined || profile === null) {
      return;
    }
    localStorage.setItem('selected-franchise-id', id);
    setSelectedFranchise(franchise);
    setDisplayedLocations(
      profile.locations.filter(
        (location) => location.franchiseId === franchise.id
      )
    );
    const foundLocation = profile.locations.find(
      (location) => location.franchiseId === franchise.id
    );
    setSelectedLocation(foundLocation ?? null);
    setSelectedLocations(
      profile.locations.filter(
        (location) => location.franchiseId === franchise.id
      )
    );
  }

  // Handle filtering.
  function handleFilter(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    const val = event.target.value.toLowerCase();

    const filteredLocations = userLocations.filter((location) => {
      if (location.franchiseId !== selectedFranchise?.id) {
        return false;
      }
      if (location.displayName.toLowerCase().includes(val)) {
        return true;
      }

      if (location.franchiseName.toLowerCase().includes(val)) {
        return true;
      }

      return false;
    });

    setDisplayedLocations(filteredLocations);
  }

  // Handle selected location change.
  function handleSelectedLocationsChange(id: string): void {
    const locationInSelected = selectedLocations.find(
      (location) => location.id === id
    );
    if (locationInSelected !== undefined) {
      const newSelectedLocations = selectedLocations.filter(
        (location) => location.id !== id
      );
      setSelectedLocations(newSelectedLocations);
      // Cache the selected location ids.
      const cachedLocationIds = newSelectedLocations.map(
        (location) => location.id
      );
      localStorage.setItem(
        `franchise-${selectedFranchise?.id}-selected-location-ids`,
        cachedLocationIds.join(',')
      );
      return;
    }
    const location = profile?.locations.find(
      (location: Location) => location.id === id
    );
    if (location === undefined) {
      return;
    }
    setSelectedLocations([...selectedLocations, location]);
    // Cache the selected location ids.
    const cachedLocationIds = [...selectedLocations, location].map(
      (location) => location.id
    );
    localStorage.setItem(
      `franchise-${selectedFranchise?.id}-selected-location-ids`,
      cachedLocationIds.join(',')
    );
  }

  return {
    profile,
    handleProfileChange,
    userFranchises,
    setUserFranchises,
    franchiseUsers,
    setFranchiseUsers,
    franchiseLocations,
    setFranchiseLocations,
    franchiseConfiguration,
    setFranchiseConfiguration,
    userLocations,
    setUserLocations,
    selectedFranchise,
    handleFranchiseChange,
    displayedLocations,
    setDisplayedLocations,
    selectedLocation,
    setSelectedLocation,
    selectedLocations,
    handleSelectedLocationsChange,
    selectedDates,
    setSelectedDates,
    toggled,
    handleToggle,
    handleFilter,
  };
};
