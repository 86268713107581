/**
 * Cameras Page
 * This page allows the user to view live and recorded video
 * from the cameras at a location.
 */
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { useData } from '../../hooks/useData';
import {
  useCamera,
  DEFAULT_NUM_CAMERAS_DISPLAYED,
} from '../../hooks/useCamera';

import { getLocationCameras } from '../../utils/api';
import { useHotkeys } from 'react-hotkeys-hook';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationSingle from '../../components/dropdowns/DropdownLocationSingle';
import CameraSelector from '../../components/cameras/CameraSelector';
import CameraPlusMinus from '../../components/cameras/CameraPlusMinus';
import DateSelector from '../../components/cameras/DateSelector';
import LiveButton from '../../components/cameras/LiveButton';
import DownloadButton from '../../components/cameras/DownloadButton';
import VideoPlayerKinesis from '../../components/cameras/VideoPlayerKinesis';
import DownloadVideoModal from '../../components/modals/ModalDownloadVideo';
import CvToggleButton from '../../components/cameras/CvToggleButton';
import PauseSkipButton from '../../components/cameras/PauseSkipButton';
import PlaybackSpeedControls from '../../components/cameras/PlaybackSpeedControls';
import PageSelector from '../../components/cameras/PageSelector';
import GridListToggle from '../../components/cameras/GridListToggle';
import PageLayout from '../../components/ui/PageLayout';
import { CUSTOM_VIDEO_LOCATIONS } from '../../constants';
import VideoPlayerCustom from '../../components/cameras/VideoPlayerCustom';
import EditButton from '../../components/buttons/EditButton';
import CameraOrderModal from '../../components/cameras/order/CameraOrderModal';
import CameraDetectionsTable from '../../components/tables/CameraDetectionsTable';

export default function Cameras(): JSX.Element {
  const { userLocations, selectedLocation, setSelectedLocation } = useData();
  const {
    selectedCamera,
    setSelectedCamera,
    visibleCameras,
    setVisibleCameras,
    numCamerasDisplayed,
    setNumCamerasDisplayed,
    setVideoDateTime,
    setIsLive,
    currentPage,
    setTotalPages,
    isDownloading,
    setIsDownloading,
    isGrid,
    kinesisVideo,
    setKinesisVideo,
    availableCameras,
    setAvailableCameras,
    showCvToggle,
    setShowCvToggle,
    showCvStream,
    setShowCvStream,
    showCvDtStream,
    setShowCvDtStream,
    showMonitorStream,
    setShowMonitorStream,
    hasCvStream,
    setHasCvStream,
    hasCvDtStream,
    setHasCvDtStream,
    hasMonitorStream,
    setHasMonitorStream,
    setIsEditing,
    cameraOrder,
    setCameraOrder,
  } = useCamera();

  // Set the camera order from local storage.
  useEffect(() => {
    setCameraOrder([]);
    const cachedCameraOrder = localStorage.getItem(
      `location-${selectedLocation?.id}-camera-order`
    );
    if (cachedCameraOrder !== null) {
      try {
        setCameraOrder(JSON.parse(cachedCameraOrder) as string[]);
      } catch (error) {
        console.error(error);
        setCameraOrder([]);
      }
    } else {
      setCameraOrder([]);
    }
  }, [selectedLocation]);

  // Get AWS credentials on load.
  useEffect(() => {
    async function setUpKinesis(): Promise<void> {
      const userCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: import.meta.env.VITE_AWS_REGION,
        credentials: Auth.essentialCredentials(userCredentials),
      });
      setKinesisVideo(new AWS.KinesisVideo());
    }
    setUpKinesis().catch((error) => {
      console.error('Error setting up Kinesis', error);
    });
  }, []);

  // Set user locations on load.
  useEffect(() => {
    checkSearchParams();
  }, [userLocations]);

  // Get the location's cameras when the selected location changes.
  useEffect(() => {
    async function handleGetCameras(): Promise<void> {
      if (selectedLocation === null) {
        return;
      }
      const cameras = await getLocationCameras(selectedLocation);
      cameras.sort((a, b) => {
        const aIndex = cameraOrder.indexOf(a.id);
        const bIndex = cameraOrder.indexOf(b.id);
        return aIndex - bIndex;
      });
      const allCameras = {
        id: '-1',
        displayName: 'All Cameras',
      };
      setAvailableCameras([allCameras, ...cameras]);
      if (cameras.length > 0) {
        setSelectedCamera(allCameras);
        setNumCamerasDisplayed(
          Math.min(cameras.length, DEFAULT_NUM_CAMERAS_DISPLAYED)
        );
      }
    }
    handleGetCameras().catch((error) => {
      console.error('Error getting cameras', error);
    });
  }, [selectedLocation, cameraOrder]);

  // Change the number of cameras displayed and the total pages when the number of cameras changes.
  useEffect(() => {
    if (availableCameras.length > 0) {
      const visibleCameras = availableCameras.slice(
        (currentPage - 1) * numCamerasDisplayed + 1,
        currentPage * numCamerasDisplayed + 1
      );
      setVisibleCameras(visibleCameras);

      const pages = Math.ceil(
        (availableCameras.length - 1) / numCamerasDisplayed
      );
      setTotalPages(pages);
    }
  }, [numCamerasDisplayed, availableCameras]);

  // Change the visible cameras when the current page changes.
  useEffect(() => {
    if (availableCameras.length > 0) {
      const visibleCameras = availableCameras.slice(
        (currentPage - 1) * numCamerasDisplayed + 1,
        currentPage * numCamerasDisplayed + 1
      );
      setVisibleCameras(visibleCameras);
    }
  }, [currentPage]);

  // Check whether or not the location has a CV stream.
  useEffect(() => {
    if (selectedLocation === null || kinesisVideo === undefined) {
      return;
    }

    setHasCvStream(false);
    setHasCvDtStream(false);
    setHasMonitorStream(false);
    setShowCvStream(false);
    setShowCvDtStream(false);
    setShowMonitorStream(false);

    // Check Kinesis to see whether or not the stream exists.
    kinesisVideo.describeStream(
      { StreamName: `CV_${selectedLocation.id}` },
      (err, data) => {
        console.log(err);
        setHasCvStream(true);
      }
    );

    kinesisVideo.describeStream(
      { StreamName: `CV_DT_${selectedLocation.id}` },
      (err, data) => {
        console.log(err);
        setHasCvDtStream(true);
      }
    );

    kinesisVideo.describeStream(
      { StreamName: `MONITOR_${selectedLocation.id}` },
      (err, data) => {
        console.log(err);
        setHasMonitorStream(true);
      }
    );
  }, [selectedLocation]);

  // Show the CV toggle when the user presses CTRL+C+V.
  useHotkeys(
    'ctrl+c+v',
    () => {
      setShowCvToggle(!showCvToggle);
    },
    [showCvToggle, setShowCvToggle]
  );

  // Check for search parameters and set the selected location and camera.
  function checkSearchParams(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.lid !== undefined && params.lid !== '' && params.lid !== null) {
      const location = userLocations.find(
        (location) => location.id.toString() === params.lid
      );
      if (location !== undefined) {
        setSelectedLocation(location);
      }
    }
    if (params.cid !== undefined && params.cid !== '' && params.cid !== null) {
      const camera = availableCameras.find(
        (camera) => camera.id.toString() === params.cid
      );
      if (camera !== undefined) {
        setSelectedCamera(camera);
      }
    }
    if (params.dt !== undefined && params.dt !== '' && params.dt !== null) {
      const date = new Date(params.dt);
      setVideoDateTime(date);
      setIsLive(false);
    }
  }

  return (
    <PageLayout>
      <div data-cy="cameras">
        <div className="flex flex-col items-center justify-center w-full h-full">
          {/* Top Bar */}
          <div className="flex flex-wrap items-center justify-between gap-4">
            <DropdownFranchise />
            <DropdownLocationSingle />
            <CameraSelector />
            <CameraPlusMinus />
            <DateSelector />
            <LiveButton />
            <DownloadButton />
            {showCvToggle && hasCvStream && <CvToggleButton type="LOBBY" />}
            {showCvToggle && hasCvDtStream && <CvToggleButton type="DT" />}
            {showCvToggle && hasMonitorStream && <CvToggleButton type="MONITOR" />}
          </div>
          {/* 2nd Top Bar */}
          <div className="flex flex-wrap items-center justify-between">
            <PauseSkipButton />
            <PlaybackSpeedControls />
            <PageSelector />
            <GridListToggle />
            <EditButton
              onClick={() => {
                setIsEditing(true);
              }}
            />
          </div>
          {/* Video Players Grid */}
          <div
            className={
              !isGrid
                ? 'grid grid-cols-1 gap-4'
                : 'grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4'
            }
          >
            {/* Video player for locations on Kinesis. */}
            {selectedLocation !== null &&
              selectedCamera !== null &&
              selectedCamera.id === '-1' &&
              !showCvDtStream &&
              !showCvStream &&
              !showMonitorStream &&
              !CUSTOM_VIDEO_LOCATIONS.includes(selectedLocation.id) &&
              visibleCameras.map(
                (camera) =>
                  camera.id !== '-1' && (
                    <VideoPlayerKinesis
                      key={camera.id}
                      location={selectedLocation}
                      camera={camera.id}
                    />
                  )
              )}
            {/* Video player for locations on custom streaming pipeline. */}
            {selectedLocation !== null &&
              selectedCamera !== null &&
              selectedCamera.id === '-1' &&
              !showCvDtStream &&
              !showCvStream &&
              !showMonitorStream &&
              CUSTOM_VIDEO_LOCATIONS.includes(selectedLocation.id) &&
              visibleCameras.map(
                (camera) =>
                  camera.id !== '-1' && (
                    <VideoPlayerCustom
                      key={camera.id}
                      location={selectedLocation}
                      cameraId={camera.id}
                    />
                  )
              )}
          </div>
        </div>
        {/* Video player for locations on Kinesis. */}
        {selectedLocation !== null &&
          selectedCamera !== null &&
          selectedCamera.id !== '-1' &&
          !showCvStream &&
          !CUSTOM_VIDEO_LOCATIONS.includes(selectedLocation.id) &&
          !showCvDtStream && 
          !showMonitorStream && (
            <VideoPlayerKinesis
              location={selectedLocation}
              camera={selectedCamera.id}
            />
          )}
        {/* Video player for locations on custom streaming pipeline. */}
        {selectedLocation !== null &&
          selectedCamera !== null &&
          selectedCamera.id !== '-1' &&
          !showCvStream &&
          CUSTOM_VIDEO_LOCATIONS.includes(selectedLocation.id) &&
          !showCvDtStream &&
          !showMonitorStream && (
            <VideoPlayerKinesis
              location={selectedLocation}
              camera={selectedCamera.id}
            />
          )}
        {/* CV Stream */}
        {selectedLocation !== null && showCvStream && hasCvStream && (
          <VideoPlayerKinesis location={selectedLocation} camera="CV" />
        )}

        {/* CV DT Stream */}
        {selectedLocation !== null && showCvDtStream && hasCvDtStream && (
          <VideoPlayerKinesis location={selectedLocation} camera="CV_DT" />
        )}

        {/* MONITOR Stream */}
        {selectedLocation !== null && showMonitorStream && hasMonitorStream && (
          <VideoPlayerKinesis location={selectedLocation} camera="MONITOR" />
        )}

        {/* Download Video Modal */}
        <DownloadVideoModal
          isOpen={isDownloading}
          setIsOpen={setIsDownloading}
        />
      </div>
      <CameraOrderModal />
      {showCvToggle && ((showCvStream && hasCvStream) || (showCvDtStream && hasCvDtStream) || (showMonitorStream && hasMonitorStream)) && <CameraDetectionsTable />}
    </PageLayout>
  );
}
