import { Endpoint, KinesisVideoArchivedMedia } from 'aws-sdk';
import type { KinesisVideo } from 'aws-sdk';
import type { GetDataEndpointOutput } from 'aws-sdk/clients/kinesisvideo';
import type { GetHLSStreamingSessionURLInput } from 'aws-sdk/clients/kinesisvideoarchivedmedia';
import { getTimezoneOffset } from 'date-fns-tz';
import { getUserToken } from './user';
import { getMonthNumber } from './time';

/**
 * @description Get the endpoint of the stream.
 * @param streamName The name of the stream.
 * @param kinesisVideo The KinesisVideo object.
 * @returns The endpoint of the stream.
 */
export async function getLiveStream(
  streamName: string,
  kinesisVideo: KinesisVideo
): Promise<string | undefined> {
  const endpoint = await kinesisVideo
    .getDataEndpoint({
      StreamName: streamName,
      APIName: 'GET_HLS_STREAMING_SESSION_URL',
    })
    .promise();

  if (endpoint.DataEndpoint !== undefined) {
    const url = await getStreamSessionUrl(endpoint.DataEndpoint, streamName, {
      PlaybackMode: 'LIVE',
      DisplayFragmentTimestamp: 'ALWAYS',
      Expires: 600,
      ContainerFormat: 'FRAGMENTED_MP4',
      DiscontinuityMode: 'ALWAYS',
      HLSFragmentSelector: {
        FragmentSelectorType: 'SERVER_TIMESTAMP',
      },
    });
    return url;
  } else {
    throw new Error('Cannot get the URL.');
  }
}

/**
 * @description Get the endpoint of the stream.
 * @param endpoint The endpoint of the stream.
 * @param streamName The name of the stream.
 * @param options The options of the stream.
 * @returns The endpoint of the stream.
 */
async function getStreamSessionUrl(
  endpoint: string,
  streamName: string,
  options: any
): Promise<string | undefined> {
  const kinesisVideoArchivedMedia = new KinesisVideoArchivedMedia({
    region: import.meta.env.VITE_AWS_REGION,
    endpoint: new Endpoint(endpoint),
  });

  const input: GetHLSStreamingSessionURLInput = {
    StreamName: streamName,
    ...options,
  };

  const data = await kinesisVideoArchivedMedia
    .getHLSStreamingSessionURL(input)
    .promise();

  return data.HLSStreamingSessionURL;
}

/**
 * @description Get the endpoint of the stream.
 * @param streamName The name of the stream.
 * @param kinesisVideo The KinesisVideo object.
 * @param startTime The start time of the stream.
 * @param endTime The end time of the stream.
 * @returns The endpoint of the stream.
 */
export async function getHistoricalStream(
  streamName: string,
  kinesisVideo: KinesisVideo,
  startTime: Date,
  endTime: Date
): Promise<GetDataEndpointOutput | string | undefined> {
  const endpoint = await kinesisVideo
    .getDataEndpoint({
      StreamName: streamName,
      APIName: 'GET_HLS_STREAMING_SESSION_URL',
    })
    .promise();

  if (endpoint.DataEndpoint !== undefined) {
    const url = await getStreamSessionUrl(endpoint.DataEndpoint, streamName, {
      PlaybackMode: 'ON_DEMAND',
      DisplayFragmentTimestamp: 'ALWAYS',
      Expires: 600,
      ContainerFormat: 'FRAGMENTED_MP4',
      DiscontinuityMode: 'ALWAYS',
      MaxMediaPlaylistFragmentResults: 5000,
      HLSFragmentSelector: {
        FragmentSelectorType: 'SERVER_TIMESTAMP',
        TimestampRange: {
          StartTimestamp: startTime,
          EndTimestamp: endTime,
        },
      },
    });
    return url;
  } else {
    throw new Error('Cannot get the URL.');
  }
}

export async function getLiveManifestUrl(
  locationId: string,
  cameraId: string
): Promise<string | null> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return null;
  }

  const url = `${
    import.meta.env.VITE_VIDEO_API_URL
  }?locationId=${locationId}&cameraId=${cameraId}`;

  // Make the request.
  const res = await fetch(url, {
    headers: {
      Authorization: token,
    },
  });

  console.log(res.status);

  return await res.text();
}

export async function getHistoricalManifestUrl(
  locationId: string,
  cameraId: string,
  startTime: Date,
  endTime: Date
): Promise<string | null> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return null;
  }

  const startUtc = startTime.toUTCString();
  const endUtc = endTime.toUTCString();
  const startSplit = startUtc.split(' ');
  const endSplit = endUtc.split(' ');
  const startMonth = getMonthNumber(startSplit[2]);
  const startDate = startSplit[1];
  const startYear = startSplit[3];
  const startHour = startSplit[4].split(':')[0];
  const startMinute = startSplit[4].split(':')[1];
  const endHour = endSplit[4].split(':')[0];
  const endMinute = endSplit[4].split(':')[1];

  // Pad the month with a 0 if it is less than 10.
  const paddedStartMonth = startMonth < 10 ? `0${startMonth}` : startMonth;

  const date = `${startYear}-${paddedStartMonth}-${startDate}`;
  const start = `${startHour}:${startMinute}`;
  const end = `${endHour}:${endMinute}`;
  const url = `${
    import.meta.env.VITE_VIDEO_API_URL
  }?locationId=${locationId}&cameraId=${cameraId}&date=${date}&start=${start}&end=${end}`;
  // Make the request.
  const res = await fetch(url, {
    headers: {
      Authorization: token,
    },
  });

  console.log(res.status);
  return await res.text();
}

/**
 * @description Gets the stream times for the video based on the videoDateTime prop
 * and the timezone of the location.
 */
export function getStreamTimes(
  videoDateTime: Date,
  locationTimezone: string
): { startTime: Date; endTime: Date } {
  const d = new Date(videoDateTime);
  const localOffset = -1 * d.getTimezoneOffset() * 60 * 1000;
  const baseOffset = getTimezoneOffset(locationTimezone);
  const adjusted = new Date(d.valueOf() - (baseOffset - localOffset));
  const videoLengthMs = 10 * 60 * 1000;
  const adjustedEnd = new Date(adjusted.valueOf() + videoLengthMs);

  return {
    startTime: adjusted,
    endTime: adjustedEnd,
  };
}
