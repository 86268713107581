/**
 * Presentational component for playing video.
 *
 * Connects to AWS Kinesis for streaming video.
 */
import { useEffect, useRef, useState } from 'react';

import videojs from 'video.js';
import type Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

import {
  getHistoricalStream,
  getLiveStream,
  getStreamTimes,
} from '../../utils/video';
import type { Location } from '../../types/Location';
import { useCamera } from '../../hooks/useCamera';
import VideoPlayer from './VideoPlayer';

export default function VideoPlayerKinesis({
  location,
  camera,
}: {
  location: Location;
  camera: string;
}): JSX.Element {
  const {
    kinesisVideo,
    isLive,
    playbackSpeed,
    videoDateTime,
    setVideoDateTime,
    isPlaying,
    setIsPlaying,
  } = useCamera();
  const videoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<Player | null>(null);

  // On load, initialize videojs
  useEffect(() => {
    if (videoRef.current === null) {
      return;
    }
    const playerObject = videojs(videoRef.current, {
      autoplay: true,
      muted: false,
      playsinline: true,
      controls: true,
      preload: 'auto',
      userActions: {
        click: false,
      },
      html5: {
        vhs: {
          overrideNative: true,
        },
      },
    });
    setPlayer(playerObject);

    // Add listeners.
    playerObject.on('error', () => {
      playerObject.load();
      playerObject.play()?.catch((err) => {
        console.log(err);
      });
    });

    playerObject.on('loadedmetadata', () => {
      const w = wrapperRef.current?.clientWidth;
      playerObject.width(w);
    });

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }

    setIsPlaying(true);

    // Cleanup.
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [videoRef.current]);

  // Play or pause the video based on the isPlaying prop.
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (!isPlaying) {
      player.pause();
    } else {
      player.play()?.catch((err) => {
        console.log(err);
      });
    }
  }, [isPlaying]);

  // Change video speed.
  useEffect(() => {
    if (player === null) {
      return;
    }
    try {
      player.playbackRate(playbackSpeed);
      player.defaultPlaybackRate(playbackSpeed);
    } catch (err) {
      console.log(err);
    }
  }, [playbackSpeed]);

  // Go to live or historical video.
  useEffect(() => {
    if (player === null) {
      return;
    }

    if (isLive) {
      goToLive().catch((err) => {
        console.log(err);
      });
    } else {
      goToHistorical().catch((err) => {
        console.log(err);
      });
    }
  }, [player, isLive, videoDateTime, location, camera]);

  /**
   * @description Gets the stream name for the video.
   * @returns The stream name for the video.
   */
  function getStreamName(): string {
    let streamName = '';
    if (camera === 'CV') {
      streamName = `CV_${location.id}`;
    } else if (camera === 'CV_DT') {
      streamName = `CV_DT_${location.id}`;
    } else if (camera === 'MONITOR') {
      streamName = `MONITOR_${location.id}`;
    } else {
      streamName = `${location.id}_${camera}`;
    }
    return streamName;
  }

  /**
   * @description Jumps the video to live.
   */
  async function goToLive(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      const streamUrl = await getLiveStream(getStreamName(), kinesisVideo);

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
      player.playbackRate(1);
      player.defaultPlaybackRate(1);
    } catch (err) {
      console.log('Error going to live stream.');
      console.warn(err);
    }
  }

  /**
   * @description Jumps the video to a historical stream.
   */
  async function goToHistorical(): Promise<void> {
    if (player === null || kinesisVideo === undefined) {
      return;
    }

    try {
      player.on('ended', () => {
        console.log(videoDateTime)
        setVideoDateTime(new Date(videoDateTime.valueOf() + (10 * 60 * 1000)));
        console.log(videoDateTime)
      });
      const { startTime, endTime } = getStreamTimes(
        videoDateTime,
        location.timezone
      );

      const streamUrl = await getHistoricalStream(
        getStreamName(),
        kinesisVideo,
        startTime,
        endTime
      );

      player.src({
        src: streamUrl,
        type: 'application/x-mpegURL',
      });
    } catch (err) {
      console.log('Error going to historical stream.');
      console.warn(err);
    }
  }

  return (
    <VideoPlayer
      location={location}
      cameraId={camera}
      videoRef={videoRef}
      wrapperRef={wrapperRef}
    />
  );
}
